<template>
  <div>
    <div>
      <el-form :inline="true" :model="form" ref="departmentInfo" size="small">
        <el-form-item label="申请时间">
          <el-date-picker
            v-model="searchCondition_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions0"
            @change="searchConditionDateChage"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 20px" label="公司名称">
          <el-input
            v-model.trim="form.name"
            placeholder="请输入公司名称"
            style="width: 200px"
            maxlength="64"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="开票状态">
          <el-select v-model="form.state" placeholder="请选择" clearable>
            <el-option
              v-for="item in stateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.vakue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button type="primary" @click="getTableData">搜索</el-button>
          <el-button type="warning">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        :height="tableHeight"
        border
      >
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
        <el-table-column prop="name" label="公司名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="number"
          label="税号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="开票金额"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="状态"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="申请时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="number"
          label="开票时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="goToDelive(scope.row)">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-box mt20r">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="changeCurrent"
        :current-page="form.current"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      tableData: [{name:"张氏集团",number: "123"}],
      searchCondition_date: [], //搜索条件申请时间
      form: {
        size: 10,
        current: 1,
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      stateOptions: [
        { lable: "不限", value: null },
        { lable: "待开票", value: 1 },
        { lable: "已开票", value: 2 },
      ],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    searchConditionDateChage(val) {
      if (val) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
    goToDelive(row){
      this.$router.push("/home/invoceManagerDetail")
    },
    //获取数据
    getTableData() {},
    // 分页
    changeCurrent(val) {
      this.form.current = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.form.current = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  text-align: right;
}
</style>
